import { graphql, useStaticQuery } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import OrderPageHeader from '../components/order-page/OrderPageHeader';
import Page from '../components/Page';
import { lightTheme } from '../styles/themes';

const OrderPage = () => {
  const { data } = useStaticQuery(query);
  const { seo } = data.edges[0].node;

  return (
    <Page seoTitle={seo.title} seoDescription={seo.description}>
      <ThemeProvider theme={lightTheme}>
        <OrderPageHeader />
      </ThemeProvider>
    </Page>
  );
};

const query = graphql`
  query {
    data: allOrderPageYaml {
      edges {
        node {
          seo {
            title
            description
          }
        }
      }
    }
  }
`;

export default OrderPage;
