import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';

import CaptionedTitle from '../CaptionedTitle';
import PageHeader from '../PageHeader';

const OrderPageHeader: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption }
  } = data.edges[0].node;

  return (
    <PageHeader>
      <Col>
        <CaptionedTitle as="h2" caption={caption}>
          {title}
        </CaptionedTitle>
      </Col>
    </PageHeader>
  );
};

const query = graphql`
  query {
    data: allOrderPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
          }
        }
      }
    }
  }
`;

export default OrderPageHeader;
